<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="進行中マニフェスト" />
    <AppSideMenu />
    <main class="l-main">
      <ToastMessage :toastMsg.sync="toastMsg" />
      <!-- 検索フォーム ============================================================ -->
      <SearchForm
        :manifestStatusCount="manifestStatusCount"
        @search="getManifestSearchApi"
      />

      <!-- 検索結果 ============================================================ -->
      <div class="l-container">
        <ErrorMessage :errMsgs="errMsgs" />

        <div v-if="manifestList.length" class="l-flex cols l-item" :class="{ disabled: isCallApiProcess }">
          <div
            class="c-btn secondary small upload"
            style="margin-left: auto;!important;width: 16rem;"
            @click="onClickCsvUploadModalDisplayButton"
          >
            CSVアップロード
          </div>
          <div
              class="c-btn secondary small download"
              style="width: 16rem;"
              @click="$refs.csvdownloadmodal.updateCSVModal(true)"
              @click_backup="clickCSVSearchHandler"
          >
            CSVダウンロード
          </div>
        </div>

        <div class="c-infoList__wrap">
          <div class="c-infoList scroll l-box">
            <div class="c-infoList__row head">
              <div class="c-infoList__cell md-s">マニフェスト番号</div>
              <div class="c-infoList__cell sm">廃棄物区分</div>
              <div class="c-infoList__cell sm">廃棄物名称</div>
              <div class="c-infoList__cell sm">詳細名称</div>
              <div class="c-infoList__cell sm">数量</div>
              <div class="c-infoList__cell md-s">
                <a @click="clickSortChangeHandler('collection_date')">
                    <span class="c-infoList__cell__sort" :class="{
                        asc: sortParams.type.toLowerCase() === 'asc' && sortParams.column === 'collection_date',
                        desc: sortParams.type.toLowerCase() === 'desc' && sortParams.column === 'collection_date',
                        'sort-disabled': sortParams.column !== 'collection_date',
                      }"
                    >引き渡し日&nbsp;/&nbsp;交付日</span>
                </a>
              </div>
              <div class="c-infoList__cell sm">運搬区間</div>
              <div class="c-infoList__cell md-s">
                <a @click="clickSortChangeHandler('collection_report_date')">
                    <span class="c-infoList__cell__sort" :class="{
                        asc: sortParams.type.toLowerCase() === 'asc' && sortParams.column === 'collection_report_date',
                        desc: sortParams.type.toLowerCase() === 'desc' && sortParams.column === 'collection_report_date',
                        'sort-disabled': sortParams.column !== 'collection_report_date',
                      }"
                    >運搬終了日&nbsp;/&nbsp;報告日</span>
                </a>
              </div>
              <div class="c-infoList__cell md-s">
                <a @click="clickSortChangeHandler('disposal_report_date')">
                    <span class="c-infoList__cell__sort" :class="{
                        asc: sortParams.type.toLowerCase() === 'asc' && sortParams.column === 'disposal_report_date',
                        desc: sortParams.type.toLowerCase() === 'desc' && sortParams.column === 'disposal_report_date',
                        'sort-disabled': sortParams.column !== 'disposal_report_date',
                      }"
                    >処分終了日&nbsp;/&nbsp;報告日</span>
                </a>
              </div>
              <div class="c-infoList__cell md-s">
                <a @click="clickSortChangeHandler('final_disposal_report_date')">
                    <span class="c-infoList__cell__sort" :class="{
                        asc: sortParams.type.toLowerCase() === 'asc' && sortParams.column === 'final_disposal_report_date',
                        desc: sortParams.type.toLowerCase() === 'desc' && sortParams.column === 'final_disposal_report_date',
                        'sort-disabled': sortParams.column !== 'final_disposal_report_date',
                      }"
                    >最終処分終了日&nbsp;/&nbsp;報告日</span>
                </a>
              </div>
            </div>

            <!-- 検索結果あり -->
            <template v-if="manifestList.length">
            <div
                v-for="(manifest, index) of manifestList"
                :key="'manifest-' + index"
                class="c-infoList__row list"
                @click="toDetail(manifest.manifestId)"
            >
              <div class="c-infoList__cell md-s c-tooltip">
                <router-link @click.native="setManifestProgressPage()" :to="`/manifest/${manifest.manifestId}`">
                  {{ manifest.manifestNo | blankText }}
                  {{ manifest.manifestRegistType == manifestRegistType.COLLECTOR_REGIST ?  "（運代）" : "" }}
                  {{ manifest.manifestRegistType == manifestRegistType.DISPOSER_REGIST ? "（処代）" : "" }}
                </router-link>
                <div v-if="pendingStatus.includes(manifest.manifestStatusType.id)"
                     class="c-status info-blue">
                  <div class="c-infoList__popup">
                      <span>報告修正承認待ちです。</span>
                  </div>
                </div>
                <div v-if="deletePendingStatus.includes(manifest.manifestStatusType.id)"
                     class="c-status info-blue">
                  <div class="c-infoList__popup">
                      <span>報告取消承認待ちです。</span>
                  </div>
                </div>
                <div v-else-if="!manifest.issueDate || manifest.allReportLimit === limitStatus.WARNING"
                     class="c-status info-yellow">
                  <div class="c-infoList__popup">
                    <span v-if="!manifest.issueDate">JWNET通信中です。<br></span>
                    <span v-if="manifest.collectReportLimit">運搬終了報告期限が迫っています。<br></span>
                    <span v-if="manifest.disposalReportLimit">処分終了報告期限が迫っています。<br></span>
                    <span v-if="manifest.finalDisposalReportLimit">最終処分終了報告期限が迫っています。<br></span>
                  </div>
                </div>
                <div v-else-if="!manifest.collectionDate || manifest.allReportLimit === limitStatus.DANGER"
                     class="c-status info-red">
                  <div class="c-infoList__popup">
                    <span v-if="!manifest.collectionDate">引き渡し日が登録されていません。<br></span>
                    <span v-if="manifest.collectReportLimit === limitStatus.WARNING">運搬終了報告期限が迫っています。<br></span>
                    <span v-else-if="manifest.collectReportLimit === limitStatus.DANGER">運搬終了報告期限が過ぎています。<br></span>
                    <span v-if="manifest.disposalReportLimit === limitStatus.WARNING">処分終了報告期限が迫っています。<br></span>
                    <span v-else-if="manifest.disposalReportLimit === limitStatus.DANGER">処分終了報告期限が過ぎています。<br></span>
                    <span v-if="manifest.finalDisposalReportLimit === limitStatus.WARNING">最終処分終了報告期限が迫っています。<br></span>
                    <span v-else-if="manifest.finalDisposalReportLimit === limitStatus.DANGER">最終処分終了報告期限が過ぎています。<br></span>
                  </div>
                </div>
              </div>
              <div class="c-infoList__cell sm text-dotted">
                {{ manifest.wasteType ? manifest.wasteType.name : "" }}
              </div>
              <div class="c-infoList__cell sm text-dotted">
                {{ manifest.wasteName ? manifest.wasteName.name : "" }}
              </div>
              <div class="c-infoList__cell sm text-dotted">
                {{ manifest.detailWasteName != null ? manifest.detailWasteName : "" }}
              </div>
              <div class="c-infoList__cell sm text-dotted">
                {{ manifest.wasteQuantity }} {{ manifest.wasteUnitType ?  manifest.wasteUnitType.name : "" }}
              </div>
              <div class="c-infoList__cell md-s">
                <span class="u-txt-separator">{{ manifest.collectionDate | dateJpMDddFormat }}</span>
                <span class="u-txt-sm u-txt-light-grey">{{ manifest.issueDate | dateJpMDddFormat }}</span>
              </div>

              <!-- 運搬区間 -->
              <div class="c-infoList__cell sm c-tooltip">
                <div class="c-infoText">
                  <div class="c-infoText__barWrap">
                    <span
                      v-for="(cc, index) in manifest.collectCompany.length"
                      :key="`ccindex-${index}`"
                      class="c-infoText__bar"
                      :class="{'active': !!manifest.collectCompany[index].collectionReportDate }"
                    ></span>
                  </div>
                </div>
                <div v-if="manifest.collectCompany.length" class="c-status default">
                  <div class="c-infoList__popup check">
                    <ul class="c-checkList">
                      <li v-for="(cc, index) in manifest.collectCompany"
                          :key="'collect-company-' + index"
                          class="c-checkList__each"
                      >
                        <span
                          class="c-checkList__icon"
                          :class="{'active': !!manifest.collectCompany[index].collectionReportDate }"
                        ></span>
                        {{ cc.name }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- 運搬終了日/報告日 -->
              <template v-if="manifest.collectionReportDate && !manifest.isNotAllCollectReported">
                <div class="c-infoList__cell md-s">
                  <template v-if="manifest.collectionReportDate === manifest.collectionRegistDate">
                    <span>{{ manifest.collectionReportDate | dateJpMDddFormat }}</span>
                  </template>
                  <template v-else>
                    <span class="u-txt-separator">{{ manifest.collectionReportDate | dateJpMDddFormat }}</span>
                    <span class="u-txt-sm u-txt-light-grey">{{ manifest.collectionRegistDate | dateJpMDddFormat }}</span>
                  </template>
                </div>
              </template>
              <template v-else>
                <div class="c-infoList__cell md-s c-tooltip">
                  <div class="c-infoText">
                    <div>未報告</div>
                  </div>
                  <div class="c-status" :class="(manifest.collectionReportDate || !manifest.collectReportLimit) ? 'default' : manifest.collectReportLimit">
                    <div class="c-infoList__popup sm">
                      <template v-if="manifest.collectReportLimit">
                        <span v-if="manifest.jwnetItemType.isSpecial">期限: {{ getDeadline(manifest.issueDate, deadline.SPECIAL_WASTE_REPORT_INTERVAL) | dateJpMDddFormat }}<br></span>
                        <span v-else>期限: {{ getDeadline(manifest.issueDate, deadline.COLLECT_REPORT_INTERVAL) | dateJpMDddFormat }}<br></span>
                        <span v-if="manifest.collectReportLimit === limitStatus.DANGER">運搬終了報告期限が過ぎています。<br></span>
                      </template>
                      <span class="l-flex center">
                        <span>  
                          {{ getNotYetCollectReportCompany(manifest.collectCompany).name }}
                        </span>
                        <span class="l-flex center" style="min-width: 11rem;">  
                          <span class="c-info__tel"></span>
                          <span style="word-break: break-all;">
                            {{ getNotYetCollectReportCompany(manifest.collectCompany).tel }}
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </template>

              <!-- 処分終了日/報告日 -->
              <template v-if="manifest.disposalReportDate">
                <div class="c-infoList__cell md-s">
                  <template v-if="manifest.disposalReportDate === manifest.disposalRegistDate">
                    <span>{{ manifest.disposalReportDate | dateJpMDddFormat }}</span>
                  </template>
                  <template v-else>
                    <span class="u-txt-separator">{{ manifest.disposalReportDate | dateJpMDddFormat }}</span>
                    <span class="u-txt-sm u-txt-light-grey">{{ manifest.disposalRegistDate | dateJpMDddFormat }}</span>
                  </template>
                </div>
              </template>
              <template v-else-if="!manifest.issueDate || !manifest.disposalReportLimit">
                <div class="c-infoList__cell md-s">
                  <span>未報告</span>
                </div>
              </template>
              <template v-else>
                <div class="c-infoList__cell md-s c-tooltip">
                  <div class="c-infoText">
                    <div>未報告</div>
                  </div>
                  <div v-if="manifest.disposalReportLimit" class="c-status" :class="manifest.disposalReportLimit">
                    <div class="c-infoList__popup sm">
                      <span v-if="manifest.jwnetItemType.isSpecial">期限: {{ getDeadline(manifest.issueDate, deadline.SPECIAL_WASTE_REPORT_INTERVAL) | dateJpMDddFormat }}<br></span>
                      <span v-else>期限: {{ getDeadline(manifest.issueDate, deadline.DISPOSAL_REPORT_INTERVAL) | dateJpMDddFormat }}<br></span>
                      <span v-if="manifest.disposalReportLimit === limitStatus.DANGER">処分終了報告期限が過ぎています。<br></span>
                    </div>
                  </div>
                </div>
              </template>

              <!-- 最終処分終了日/報告日 -->
              <template v-if="manifest.finalDisposalReportDate">
                <div class="c-infoList__cell md-s">
                  <template v-if="manifest.finalDisposalReportDate === manifest.finalDisposalRegistDate">
                    <span>{{ manifest.finalDisposalReportDate | dateJpMDddFormat }}</span>
                  </template>
                  <template v-else>
                    <span class="u-txt-separator">{{ manifest.finalDisposalReportDate | dateJpMDddFormat }}</span>
                    <span class="u-txt-sm u-txt-light-grey">{{ manifest.finalDisposalRegistDate | dateJpMDddFormat }}</span>
                  </template>
                </div>
              </template>
              <template v-else-if="!manifest.issueDate || !manifest.finalDisposalReportLimit">
                <div class="c-infoList__cell md-s">
                  <span>未報告</span>
                </div>
              </template>
              <template v-else>
                <div class="c-infoList__cell md-s c-tooltip">
                  <div class="c-infoText">
                    <div>未報告</div>
                  </div>
                  <div v-if="manifest.finalDisposalReportLimit" class="c-status" :class="manifest.finalDisposalReportLimit">
                    <div class="c-infoList__popup sm">
                      <span v-if="manifest.jwnetItemType.isSpecial">期限: {{ getDeadline(manifest.issueDate, deadline.FINAL_DISPOSAL_REPORT_INTERVAL) | dateJpMDddFormat }}<br></span>
                      <span v-else>期限: {{ getDeadline(manifest.issueDate, deadline.FINAL_DISPOSAL_REPORT_INTERVAL) | dateJpMDddFormat }}<br></span>
                      <span v-if="manifest.finalDisposalReportLimit === limitStatus.DANGER">最終処分運搬終了報告期限が過ぎています。<br></span>
                    </div>
                  </div>
                </div>
              </template>

            </div>
            </template>

            <!-- 検索結果なし -->
            <template v-else>
              <div class="c-infoList__row list">
                <div class="c-infoList__cell">
                  検索条件に一致するマニフェストはありません。
                </div>
              </div>
            </template>
          </div>
        </div>

        <Pagination v-if="manifestList.length"
            :params="paginationParams"
            :page.sync="paginationParams.page"
            @callback="getManifestSearchApi(searchedParam, true)"
        />
      </div>

      <CSVDownloadModal
        ref="csvdownloadmodal"
        :paginationParams="paginationParams"
        @downloadCSVHandler="downloadCSVHandler"
      />
    </main>

    <AppFooter />
    <ManifestCsvUploadModal v-if="isDisplayCsvUploadModal" 
      v-bind:onClickCloseButton="hideCsvUploadModal"
      v-bind:onClickUploadButton="onClickUploadCsv"
      v-bind:onClickUploadRegisterCsvButton="onClickUploadRegisterCsv"
    />

  </div>
</template>

<script>
import moment from "moment";
import SearchForm from "./components/SearchForm.vue";
import Pagination from "@/components/parts/Pagination";
import search from "./mixins/search";
import { API_URL, DOMAIN } from "@/const";
import axios from "axios";
import { saveAs } from "file-saver";
import {REPORT_DEADLINE, DATE_LIMIT_STATUS} from "@/const/dateLimit";
import CSVDownloadModal from "../components/CSVDownloadModal";
import ToastMessage from "@/components/parts/ToastMessage";
import manifestCallApi from "../mixins/callApi";
import ManifestCsvUploadModal from "../components/ManifestCsvUploadModal";

export default {
  name: "manifest-progress",
  components: {
    SearchForm,
    Pagination,
    CSVDownloadModal,
    ToastMessage,
    ManifestCsvUploadModal,
  },
  mixins: [search, manifestCallApi],
  data() {
    return {
      manifestStatusCount: [],
      manifestList: [],
      // 検索内容別で持つ。ページ移動でもAPI叩くため
      searchedParam: {},
      // ページネーション用
      paginationParams: {
        page: 1,
        pageSize: 20,
        total: null,
      },
      sortParams: {
        column: "collection_date",
        type: "desc",
      },
      errMsgs: [],
      deadline: REPORT_DEADLINE,
      limitStatus: DATE_LIMIT_STATUS,
      deletePendingStatus: [
        DOMAIN.MANIFEST_STATUS.COLLECT_REPORT_DELETE_PENDING,
        DOMAIN.MANIFEST_STATUS.DISPOSAL_REPORT_DELETE_PENDING,
      ],
      pendingStatus: [
        DOMAIN.MANIFEST_STATUS.COLLECT_REPORT_FIX_PENDING,
        DOMAIN.MANIFEST_STATUS.DISPOSAL_REPORT_FIX_PENDING,
        DOMAIN.MANIFEST_STATUS.FINAL_DISPOSAL_REPORT_FIX_PENDING,
      ],
      manifestRegistType: DOMAIN.MANIFEST_REGIST_TYPE,
      toastMsg: "",
      isDisplayCsvUploadModal: false,
      isCallApiProcess: false,
    };
  },
  methods: {
    // cs202201：CSVフォーマット関連
    /**
     * CSVフォーマット選択モーダル内ダウンロードボタン押下時
     */
    downloadCSVHandler(selectID) {
      // 2022.03.09 全項目とフォーマット指定のAPIを一本化
      this.clickCSVSearchByFormatHandler(selectID);
    },

    // CSVダウンロード用の検索条件パラメーター
    getCSVDownloadSearchParam() {
      let generateStoreIdList = [];
      if (this.searchedParam.generateStoreIdList) {
        this.searchedParam.generateStoreIdList.forEach (
            generateStoreId => {
              if (generateStoreId) {
                generateStoreIdList.push(generateStoreId);
              }
            }
        )
      }
      return {
        searchConditionId: String(DOMAIN.MANIFEST_SEARCH_CONDITION.ISSUED),
        wasteName: this.searchedParam.wasteName === "" ? null : this.searchedParam.wasteName,
        collectCompanyId: this.searchedParam.collectCompanyId === "" ? null : this.searchedParam.collectCompanyId,
        collectionDateFrom: this.searchedParam.collectionDateFrom === "" ? null : this.searchedParam.collectionDateFrom,
        collectionDateTo: this.searchedParam.collectionDateTo === "" ? null : this.searchedParam.collectionDateTo,
        generateStoreId: generateStoreIdList,
        manifestSearchType: this.searchedParam.status,
        disposalCompanyId: this.searchedParam.disposalCompanyId === "" ? null : this.searchedParam.disposalCompanyId,
        manifestNo: this.searchedParam.manifestNo === "" ? null : this.searchedParam.manifestNo,
        contactNo: this.searchedParam.contactNo === "" ? null : this.searchedParam.contactNo,
        issueDateFrom: this.searchedParam.issueDateFrom === "" ? null : this.searchedParam.issueDateFrom,
        issueDateTo: this.searchedParam.issueDateTo === "" ? null : this.searchedParam.issueDateTo,
        jwnetItemTypeId: this.searchedParam.jwnetItemTypeId === "" ? null : this.searchedParam.jwnetItemTypeId,
        cbaItemId: this.searchedParam.cbaItemId === "" ? null : this.searchedParam.cbaItemId,
        routeName: this.searchedParam.routeName === "" ? null : this.searchedParam.routeName,
        routeId: this.searchedParam.routeId === "" ? null : this.searchedParam.routeId,
        wasteTypeList: this.searchedParam.wasteType,
        jwnetItemTypeFieldList: this.searchedParam.jwnetItemTypeField,
        collectReportSearchType: this.searchedParam.collectReportSearchType,
        disposalReportSearchType: this.searchedParam.disposalReportSearchType,
        finalDisposalReportSearchType: this.searchedParam.finalDisposalReportSearchType,
        termDateTypeList: this.searchedParam.termDateType,
        reportDateFrom: this.searchedParam.reportDateFrom === "" ? null : this.searchedParam.reportDateFrom,
        reportDateTo: this.searchedParam.reportDateTo === "" ? null : this.searchedParam.reportDateTo,
        registDateFrom: this.searchedParam.registDateFrom === "" ? null : this.searchedParam.registDateFrom,
        registDateTo: this.searchedParam.registDateTo === "" ? null : this.searchedParam.registDateTo,
        remarks: this.searchedParam.remarks === "" ? null : this.searchedParam.remarks,
        manifestLabel: this.searchedParam.manifestLabel === "" ? null : this.searchedParam.manifestLabel,
        isMine: this.searchedParam.isMine,
        isActive: this.searchedParam.isActive,
        sort: this.sortParams.column + ":" + this.sortParams.type,
      };
    },

    // CSVダウンロード押下
    clickCSVSearchHandler() {
      this.errMsgs = [];
      axios
        .post(API_URL.MANIFEST.CSV_DOWNLOAD
            , this.getCSVDownloadSearchParam()
            , { responseType: "blob" }
        )
        .then((res) => {
          const blob = new Blob([res.data], {
            type: res.data.type
          });
          saveAs(blob, `マニフェスト検索結果_${moment().format("YYYYMMDDHHmmss")}.csv`);
          this.$refs.csvdownloadmodal.finishedDownloadCSVHandler();
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          this.$refs.csvdownloadmodal.finishedDownloadCSVHandler();
          this.$refs.csvdownloadmodal.updateCSVModal(false);
        });
    },

    // フォーマット指定したCSVダウンロード押下
    clickCSVSearchByFormatHandler(csvformatId) {
      this.errMsgs = [];
      axios
          .post(API_URL.MANIFEST.CSV_DOWNLOAD_BY_FORMAT
              , {
                manifestSearchFileDownloadInfo: this.getCSVDownloadSearchParam(),
                csvformatId: csvformatId
              }
              , { responseType: "blob" }
          )
          .then((res) => {
            const blob = new Blob([res.data], {
              type: res.data.type
            });
            saveAs(blob, `マニフェスト検索結果_${moment().format("YYYYMMDDHHmmss")}.csv`);
            this.$refs.csvdownloadmodal.finishedDownloadCSVHandler();
          })
          .catch((err) => {
            this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
            this.$refs.csvdownloadmodal.finishedDownloadCSVHandler();
            this.$refs.csvdownloadmodal.updateCSVModal(false);
          });
    },

    // 報告日がある運搬を返す（配列）
    getActiveCollectCompany(collectCompanys) {
      let activeCompany = [];
      collectCompanys.map((cc) => {
        if (cc.collectionReportDate) {
          activeCompany.push(cc);
        }
      });
      return activeCompany;
    },

    // 運搬/処分/最終処分の期限
    checkReportLimit(issueDate, deadline) {
      if (!issueDate || !Number(deadline)) return ""; // 本番運用では発生しないが、交付日がない場合スルー
      const warning = deadline - REPORT_DEADLINE.WARNING_ALERT_INTERVAL; // 黄色の注意アラートは30日前から表示
      const today = moment();
      const targetDate = moment(issueDate);
      const diff = today.diff(targetDate, "days");
      if (warning < diff && diff <= deadline) {
        return DATE_LIMIT_STATUS.WARNING;
      } else if (diff > deadline) {
        return DATE_LIMIT_STATUS.DANGER;
      } else {
        return DATE_LIMIT_STATUS.NOT_YET;
      }
    },
    // 特別管理産業廃棄物の期限
    checkSpecialWasteReportLimit(issueDate, deadline) {
      if (!issueDate) return ""; // 本番運用では発生しないが、交付日がない場合スルー
      const warning = deadline - REPORT_DEADLINE.WARNING_ALERT_INTERVAL; // 黄色の注意アラートは30日前から表示
      const today = moment();
      const targetDate = moment(issueDate);
      const diff = today.diff(targetDate, "days");
      if (warning < diff && diff <= deadline) {
        return DATE_LIMIT_STATUS.WARNING;
      } else if (diff > deadline) {
        return DATE_LIMIT_STATUS.DANGER;
      } else {
        return DATE_LIMIT_STATUS.NOT_YET;
      }
    },
    // APIのアラート区分から変換する
    toDateLimitStatus(reportAlertTypeId) {
      if (reportAlertTypeId === 1) return DATE_LIMIT_STATUS.WARNING;
      if (reportAlertTypeId === 2) return DATE_LIMIT_STATUS.DANGER;
      return DATE_LIMIT_STATUS.NOT_YET;
    },
    // 期限日の取得
    getDeadline(issueDate, deadline) {
      return moment(issueDate).add(deadline, "days");
    },
    // 運搬区間内で未報告の運搬事業者
    getNotYetCollectReportCompany(collectCompany) {
      const notYetCompanyList = collectCompany.filter(c => !c.collectionReportDate);
      if (notYetCompanyList.length === 1) { // 未報告が1件の場合、そのままリターン
        return notYetCompanyList[0];
      } else if (notYetCompanyList.length > 1) { // 未報告が複数存在する場合、最初の区間に近いものを選択
        return  notYetCompanyList.sort((a, b) => {
          if( a.section < b.section ) return -1;
          if( a.section > b.section ) return 1;
          return 0;
        })[0];
      } else {
        return null;
      }
    },

    // ソート変更（引き渡し日、運搬終了報告日、処分終了報告日、最終処分終了報告日）
    clickSortChangeHandler(column) {
      if (this.sortParams.column !== column) {
        this.sortParams.type = "desc";
      } else {
        this.sortParams.type = this.sortParams.type.toLowerCase() === "asc" ? "desc" : "asc";
      }
      this.sortParams.column = column;

      this.$store.commit("searchParamManifestsProgress/setManifestProgressPage", 1);
      this.getManifestSearchApi(this.searchedParam, false);
    },

    toDetail(manifestId) {
      this.setManifestProgressPage()
      const transitionSource = location.pathname;
      this.$router.push(`/manifest/${manifestId}?transitionSource=${transitionSource}`);
    },
    hideCsvUploadModal() {
      this.isDisplayCsvUploadModal = false;
    },
    onClickCsvUploadModalDisplayButton() {
      this.isDisplayCsvUploadModal = true;
    },

    onClickUploadCsv(csvFile) {
      return this.postManifestCsvupload(csvFile)
        .then(this.onUpload);
    },
    onClickUploadRegisterCsv(csvFile) {
      return this.postManifestCsvregister(csvFile)
        .then(this.onUpload);
    },

    onUpload() {
      this.hideCsvUploadModal();
      this.toastMsg = "正常にアップロードしました。";
      // 再検索を行う
      this.$store.commit("searchParamManifestsProgress/setManifestProgressPage", 1);
      this.getManifestSearchApi(this.$store.getters["searchParamManifestsProgress/get"], false);
    },
    setManifestProgressPage() {
      this.$store.commit("searchParamManifestsProgress/setManifestProgressPage", this.paginationParams.page);
    },
    addRequestToLocation(requestObj) {
      const param = new URLSearchParams(requestObj)
      history.replaceState(
      {},
      null,
      this.$route.path + '?' + param
    )
    }
  },
  beforeRouteEnter(to, from, next) {
    next( (vm) => {
      if(from.path.includes("/manifest")) {
        let page = vm.$store.getters["searchParamManifestsProgress/getManifestProgressPage"]
        vm.paginationParams.page = page
      } else {
        // リセットする
        vm.$store.commit("searchParamManifestsProgress/setManifestProgressPage", 1);
      }
    })
  },
  watch: {
    manifestList() {
      this.manifestList.forEach(manifest => {
        manifest.collectReportLimit = DATE_LIMIT_STATUS.NOT_YET
        manifest.isNotAllCollectReported = manifest.collectCompany && manifest.collectCompany.length && (manifest.collectCompany.filter(cc => cc.collectionReportDate === null).length > 0)

        if (manifest.collectCompany && manifest.collectCompany.length > 0) {
          if (manifest.collectCompany.filter(cc => cc.collectReportAlertTypeId === 1).length > 0) {
            manifest.collectReportLimit = DATE_LIMIT_STATUS.WARNING
          }
          if (manifest.collectCompany.filter(cc => cc.collectReportAlertTypeId === 2).length > 0) {
            manifest.collectReportLimit = DATE_LIMIT_STATUS.DANGER
          }
        }

        manifest.disposalReportLimit = this.toDateLimitStatus(manifest.disposalReportAlertTypeId)
        manifest.finalDisposalReportLimit = this.toDateLimitStatus(manifest.finalDisposalReportAlertTypeId)

        if (manifest.collectReportLimit === DATE_LIMIT_STATUS.DANGER
            || manifest.disposalReportLimit === DATE_LIMIT_STATUS.DANGER
            || manifest.finalDisposalReportLimit === DATE_LIMIT_STATUS.DANGER) {
          manifest.allReportLimit = DATE_LIMIT_STATUS.DANGER
        } else if (manifest.collectReportLimit === DATE_LIMIT_STATUS.WARNING
            || manifest.disposalReportLimit === DATE_LIMIT_STATUS.WARNING
            || manifest.finalDisposalReportLimit === DATE_LIMIT_STATUS.WARNING) {
          manifest.allReportLimit = DATE_LIMIT_STATUS.WARNING
        }
      });
    }
  },

  beforeCreate() {
    // クエリパラメータのチェック
    if (this.$route.query.status) {
      this.$store.commit("searchParamManifestsProgress/set", {
        wasteName: "",
        manifestNo: "",
        collectionDateFrom: "",
        collectionDateTo: "",
        cbaItemId: "",
        jwnetItemTypeId: "",
        routeId: "",
        routeName: "",
        status: this.$route.query.status ? this.$route.query.status.split(",").map(s => Number(s)) : [],
        generateStoreIdList: [null],
        collectCompanyId: "",
        generateCompanyId: "",
        disposalCompanyId: "",
        disposalSiteId: "",
        wasteType: [],
        termDateType: [],
        reportDateFrom: "",
        reportDateTo: "",
        registDateFrom: "",
        registDateTo: "",
        contactNo: "",
        remarks: "",
        manifestLabel: "",
        isMine: true,
        isActive: true,
      });
    } else if (this.$route.query.registDateFrom || this.$route.query.registDateTo || this.$route.query.termDateTypeList) {
      this.$store.commit("searchParamManifestsProgress/set", {
        wasteName: "",
        manifestNo: "",
        collectionDateFrom: "",
        collectionDateTo: "",
        cbaItemId: "",
        jwnetItemTypeId: "",
        routeId: "",
        routeName: "",
        status: [],
        generateStoreIdList: [null],
        collectCompanyId: "",
        generateCompanyId: "",
        disposalCompanyId: "",
        disposalSiteId: "",
        wasteType: [],
        termDateType: this.$route.query.termDateTypeList ? this.$route.query.termDateTypeList.split(",").map(s => Number(s)) : [],
        reportDateFrom: "",
        reportDateTo: "",
        registDateFrom: this.$route.query.registDateFrom ? this.$route.query.registDateFrom : "",
        registDateTo: this.$route.query.registDateTo ? this.$route.query.registDateTo : "",
        contactNo: "",
        remarks: "",
        manifestLabel: "",
        isMine: true,
        isActive: true,
      });
    }
  },

  mounted() {
    this.getManifestCountApi();
    this.getManifestSearchApi(this.$store.getters["searchParamManifestsProgress/get"], false);
  },
};
</script>
